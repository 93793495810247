import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DirectionsIcon from "@mui/icons-material/Directions";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <section className="section footer bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="fotr col-md-4 py-1 text-justify">
              <h6>Useful Links</h6>
              <hr />
              <div>
                <a
                  href="https://ngobureau.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The NGO Bureau
                </a>
              </div>
              <div>
                <a
                  href="https://www.masakacityngoforum.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Masaka City NGO Forum
                </a>
              </div>
              <div>
                <a
                  href="https://www.mia.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ministry of Internal Affairs
                </a>
              </div>
              <div>
                <a
                  href="https://ursb.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Uganda Registration Services Bureau
                </a>
              </div>
              <div>
                <a
                  href="https://www.nira.go.ug/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Identification and Registration Authority
                </a>
              </div>
            </div>
            <div className="fotr col-md-3 py-1">
              <h6>Quick Links</h6>
              <hr />
              <div>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </div>
              <div>
                <Link to="/about-us/our-team" onClick={scrollToTop}>
                  Our Team
                </Link>
              </div>
              <div>
                <Link to="/contact-us" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </div>
              <div>
                <Link to="/about-us/who-we-are" onClick={scrollToTop}>
                  Who We Are
                </Link>
              </div>
              <div>
                <Link to="/reports-publications" onClick={scrollToTop}>
                  Reports & Publications
                </Link>
              </div>
            </div>
            <div className="fotr col-md-2 py-1">
              <h6>Social Media</h6>
              <hr />
              <div>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon /> {""} Twitter
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon /> {""} LinkedIn
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <YouTubeIcon /> {""} Youtube
                </a>
              </div>
              <div>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <FacebookOutlinedIcon /> {""} Facebook
                </a>
              </div>
              <div>
                <a
                  href="https://wa.me/256781236221"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon /> {""} WhatsApp
                </a>
              </div>
            </div>
            <div className="fotr col-md-3 py-1">
              <h6>Contact Us</h6>
              <hr />
              <div>
                <p className="text-white mb-0">
                  <a href="Tel:+256781236221">
                    <CallIcon /> {""} +256 781 236 221
                  </a>
                </p>
              </div>
              <div>
                <p className="text-white mb-0">
                  <a href="Tel:+256706333500">
                    <AddIcCallIcon /> {""} +256 706 333 500
                  </a>
                </p>
              </div>
              <div>
                <p className="text-white mb-0">
                  <a
                    href="mailto:mrf19.ug@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EmailIcon /> {""} mrf19.ug@gmail.com
                  </a>
                </p>
              </div>
              <div>
                <p className="text-white mb-0">
                  <a
                    href="https://maps.app.goo.gl/prjMStUgzsep7T8w5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DirectionsIcon /> {""} Directions: Google Maps
                  </a>
                </p>
              </div>
              <div>
                <p className="text-white mb-0">
                  <HomeIcon /> {""} P.O.Box 109 Masaka, Uganda
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-4">
              <a className="textfoot" href="/">Masaka Relief Foundation</a>
            </div>
            <div className="col-md-4">
              <small className="textfoot">
                <b>All Rights Reserved. MRF © 2021 – 2024</b>
              </small>
            </div>
            <div className="col-md-4">
              <a
              className="textfoot"
                href="https://wa.me/256785430302"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed By: e-Solutions Inc
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;