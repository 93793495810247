import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../Components/Objectives.css";
import { Link } from "react-router-dom";

function Objectives() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container objectiv">
      <div className="row">
        <div className="col-md-6">
          <div className="row extend">
            <div className="col-md-12 mb-4 text-center main-heading-para">
              <h3 className="main-heading-obj pt-2">Who We Are</h3>
              <div className="underline-card mx-auto"></div>
              <p className="texthome1">
                Masaka Relief Foundation (MRF) was founded in May 2021 and is
                headquartered in Kisuuna Village, Nyendo Mukungwe Division,
                Masaka District, Uganda.
              </p>
              <p className="texthome1">
                MRF is a Local NGO that was founded in 2021 having realized that
                in Uganda, more than a third of all inhabitants live below the
                poverty line, and that Children, Adolescents and Youths are the
                Primary Victims of this Deplorable Economic Situation. Their
                rights are neglected and abused in many ways such as lack of
                access to Education, Child Marriages, Child Labor, Street
                Living, HIV/AIDS and lack of better Health Services.
              </p>
              <p>
                <b>MRF Pillars of Community Empowerment</b>
              </p>
              <p className="texthome1">
                <ul>
                  <li>
                    MRF cherishes the proper growth and development of children
                    and empowers youth with knowledge and skills to become
                    responsible adults and to realize their life goals and
                    passionate about mothers of child bearing age.
                  </li>
                  <p></p>
                  <li>
                    MRF believes that children’s rights, social and economic
                    empowerment for adolescents and youth, community inclusion
                    in development and empowerment programs should be key as a
                    mean to overall national development, and that such programs
                    must be matched by quality and equity.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mb-4 text-center main-heading-obj">
              <h3 className="main-heading-obj pt-2">What We Do</h3>
              <div className="underline-card mx-auto"></div>
            </div>
          </div>
          <Accordion flush style={{ color: "##000000", bgcolor: "##000000" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>Advocacy</b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Advocacy within Masaka Relief Foundation (MRF) involves a
                  strategic approach to addressing cross-cutting issues that
                  emerge from the core programs. These issues could span across
                  education, child protection, livelihoods, health, and more. To
                  effectively advocate for positive ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/advocacy"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>Education</b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Education is a foundational pillar within every program of
                  Masaka Relief Foundation (MRF). The organization's commitment
                  to supporting Orphans and Vulnerable Children (OVCs) reflects
                  a holistic approach to addressing the challenges faced by
                  marginalized youth. By providing ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/education"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  Child Protection
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  MRF's initiatives in child protection encompass a
                  comprehensive array of strategies that involve both community
                  empowerment and policy advocacy. By establishing and training
                  Child Protection Committees, the organization ensures that
                  local communities are actively engaged ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/child-protection"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  Emergency and Relief
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  In times of crisis, MRF's emergency and relief efforts provide
                  essential aid to disadvantaged community groups. This goes
                  beyond immediate food assistance to encompass training in food
                  security and agricultural rehabilitation. By imparting
                  knowledge and skills related to ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/emergency-and-relief"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  Livelihoods Support
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  MRF's livelihoods support initiatives focus on equipping
                  beneficiaries with sustainable income-generating activities
                  and financial management skills. By offering training in
                  various income-generating activities, the organization not
                  only enhances economic opportunities but also ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/livelihoods-support"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  Reproductive Health
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  Masaka Relief Foundation (MRF) takes a multifaceted approach
                  to reproductive health, emphasizing behavioral change
                  communication, awareness, and sensitization. Through guidance
                  and counseling, the organization addresses the mental and
                  emotional aspects of ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/reproductive-health"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  WASH (Water, Sanitation, and Hygiene)
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  MRF's WASH initiatives encompass a range of interventions
                  aimed at improving water access, sanitation, and hygiene
                  practices within communities. By promoting community-based
                  handwashing and providing water tanks, MRF empowers
                  communities to maintain proper ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/water-sanitation-and-hygiene"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <b style={{ color: "##000000", fontSize: "14px" }}>
                  Environmental Protection and Conservation
                </b>
              </Accordion.Header>
              <Accordion.Body style={{ color: "#000000" }}>
                <p className="texthome">
                  MRF's commitment to environmental protection and conservation
                  is reflected in its multifaceted initiatives. Through the
                  training of Youth Support Groups and the formation of
                  Environmental Protection and Conservation Clubs in schools,
                  the organization instills a sense of ...
                </p>
                <div className="">
                  <Link
                    to="/what-we-do/environmental-protection-and-conservation"
                    className="btn btn-outline-danger"
                    onClick={scrollToTop}
                  >
                    Read More
                  </Link>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Objectives;
