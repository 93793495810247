import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../Components/Owl.css';
import OwlComp1 from "../Components/OwlComp1";
import OwlComp2 from "../Components/OwlComp2";
import OwlComp3 from "../Components/OwlComp3";
import OwlComp4 from "../Components/OwlComp4";
import OwlComp5 from "../Components/OwlComp5";

export class Owl extends Component {  
  render(){  
    return (  
      <div class='container-fluid' >            
        <OwlCarousel items={6}  
          className="owl-theme"  
          loop
          autoplay="true"
          autoplayTimeout="7000"
          margin={5} >  
          <div ><OwlComp1 /></div>  
          <div ><OwlComp2 /></div>  
          <div ><OwlComp3 /></div>  
          <div ><OwlComp4 /></div>  
          <div ><OwlComp5 /></div>
        </OwlCarousel>  
      </div>  
    )  
  }  
}  

export default Owl
