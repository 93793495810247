import React from "react";
import Card1 from "../Images/Headers/lynnet.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Livelihood Support.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function LivelihoodsSupport() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Livelihoods Support</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Livelihoods Support
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Livelihoods Support</b>
              </h>
              <p className="texthome1">
                MRF's livelihoods support initiatives focus on equipping
                beneficiaries with sustainable income-generating activities and
                financial management skills. By offering training in various
                income-generating activities, the organization not only enhances
                economic opportunities but also fosters a sense of empowerment
                among beneficiaries. This approach is particularly vital for
                vulnerable groups such as Orphans and Vulnerable Children
                (OVCs), adolescent girls, and young women, who are often
                excluded from mainstream economic activities. Through vocational
                skill development and the provision of startup toolkits, MRF
                lays the foundation for these individuals to create
                self-sustaining livelihoods, ultimately contributing to poverty
                reduction and community development. To expand within this
                domain, MRF looks at undertaking on the following:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Market Linkages:</b> MRF helps beneficiaries access
                    markets and establish connections with potential buyers or
                    distributors to ensure the sustainability of their
                    livelihood activities.
                  </li>
                  <p></p>
                  <li>
                    <b>Entrepreneurship Training:</b> Beyond income-generating
                    activities, entrepreneurship training equips beneficiaries
                    with essential skills for managing and growing their
                    businesses.
                  </li>
                  <p></p>
                  <li>
                    <b>Mentorship Programs:</b> Creating mentorship programs
                    where experienced individuals guide beneficiaries in their
                    chosen livelihoods can lead to long-term success.
                  </li>
                  <p></p>
                  <li>
                    <b>Community-Level Initiatives:</b> Encouraging the
                    formation of community-based savings and lending groups
                    helps promote financial management and economic resilience.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LivelihoodsSupport;
