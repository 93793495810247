import React from 'react';
import Card2 from "../Images/Logo.jpg";

function WelcomeSPin({ imageSrc }) {

    return (
      <img
        src={Card2}
        alt="spinning"
        style={{ height: 140, width: 180, borderRadius: '50%' }}
      />
    );
  }

export default WelcomeSPin
