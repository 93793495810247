import React from "react";
import Carousel from "../Components/Carousel";
import { Link } from "react-router-dom";
import "../Pages/Home.css";
import "../Pages/OurHistory.css";
import ShadowCard from "../Components/ShadowCard";
import Objectives from "../Components/Objectives";
import WelcomeSPin from "../Components/WelcomeSPin";
import Owl from "../Components/Owl";

function Home() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Carousel />
      <section className="section">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-9 text-center">
              <br></br>
              <h3 className="main-heading">
                Message From The Executive Director
              </h3>
              <div className="underline-card2 mx-auto"></div>
              <p className="texthome">
                Welcome to the inspiring world of Masaka Relief Foundation
                (MRF). We take immense pride in our remarkable journey since our
                inception in 2021, and our optimism for a bright future knows no
                bounds. Throughout our dedicated efforts in Greater Masaka, we
                have achieved tremendous success, transitioning from addressing
                critical housing issues to forging a profound bond with our
                community. Together, we stand united in our mission to eradicate
                social injustices and promote the overall well-being of our
                cherished communities.
              </p>
              <Link
                to="/about-us/our-team"
                className="btn btn-outline-danger"
                onClick={scrollToTop}
              >
                Read More
              </Link>
            </div>
            <div className="col-md-3 text-center border-start mt-5 mb-3">
              <WelcomeSPin />
            </div>
          </div>
        </div>
      </section>
      <ShadowCard />
      <Objectives />
      <div className="col-md-12 mt-3 text-center">
        <h3 className="main-heading">
          Our Partners
        </h3>
        <div className="underline-card2 mx-auto"></div>
        <br></br>
        <Owl />
      </div>
    </div>
  );
}

export default Home;
