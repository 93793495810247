import React from 'react';
import Card1 from "../Images/coming.jpg";

function VideoGallery({ videos }) {

  return (
    <div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <img
                src={Card1}
                alt="rounded shape with shadow on hover"
                height="500"
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VideoGallery;