import React, { useState } from "react";
import "../Pages/Gallery.css";
import Card1 from "../Images/Gallery/Adolescent Empowerment Program.jpeg";
import Card2 from "../Images/Gallery/Borehole1.jpeg";
import Card3 from "../Images/Gallery/Borehole3.jpeg";
import Card4 from "../Images/Gallery/Elizabeth.jpeg";
import Card5 from "../Images/Gallery/Food Items.jpeg";
import Card6 from "../Images/Gallery/Jovan Before.jpeg";
import Card7 from "../Images/Gallery/Jovan Foodstuffs.jpeg";
import Card8 from "../Images/Gallery/Jovan Receives Standing Frame.jpeg";
import Card9 from "../Images/Gallery/Launching MRF Community Borehole.JPG";
import Card10 from "../Images/Gallery/Lynnet Receives Pampers etc.jpeg";
import Card11 from "../Images/Gallery/Lynnet.jpeg";
import Card12 from "../Images/Gallery/Masaka NGO Forum Surveying Some Tanks Given Out By MRF - Nalongo.JPG";
import Card13 from "../Images/Gallery/Masaka NGO Forum Surveying Some Tanks Given Out By MRF - Namagembe.JPG";
import Card14 from "../Images/Gallery/Mukadde Namagembe.jpeg";
import Card15 from "../Images/Gallery/Namanda Sweing Machine.jpeg";
import Card16 from "../Images/Gallery/Namanda Sweing Machine2.jpeg";
import Card17 from "../Images/Gallery/Namanda.jpeg";
import Card18 from "../Images/Gallery/Namanda's Family.jpeg";
import Card20 from "../Images/Gallery/Shamira Now Receiving Formal Education.jpg";
import Card21 from "../Images/Gallery/Shamira Now Receiving Formal Education2.jpeg";
import Card22 from "../Images/Gallery/Shamiras Arm Before.jpg";
import Card23 from "../Images/Gallery/Tank Sticker.jpeg";
import Card24 from "../Images/Gallery/Tank.jpeg";
import Card25 from "../Images/Gallery/Wash Program - Water Tank (2).jpg";
import Card26 from "../Images/Gallery/Wash Program - Water Tank.jpg";
import Card27 from "../Images/Gallery/Water Tank For Mukadde Namagembe.jpeg";
import Card28 from "../Images/Gallery/Water, Snitation and Hygiene (WASH) Program (2).jpeg";
import Card29 from "../Images/Gallery/Water, Snitation and Hygiene (WASH) Program (3).jpeg";
import Card30 from "../Images/Gallery/Water, Snitation and Hygiene (WASH) Program.jpeg";
import Card31 from "../Images/MRF/A beneficiary from the MRF OutReach (2).jpg";
import Card32 from "../Images/MRF/A beneficiary from the MRF OutReach.jpg";
import Card33 from "../Images/MRF/A student holding her received sanitary pad.jpg";
import Card34 from "../Images/MRF/Beneficiaries with some of the staff from the MRF OutReach.jpg";
import Card35 from "../Images/MRF/Children OutReach.jpg";
import Card36 from "../Images/MRF/Children with their received sanitary pads at the School Reach Out (2).jpg";
import Card37 from "../Images/MRF/Children with their received sanitary pads at the School Reach Out.jpg";
import Card38 from "../Images/MRF/MRF at a School Reach Out (1).jpg";
import Card39 from "../Images/MRF/MRF at a School Reach Out (2).jpg";
import Card40 from "../Images/MRF/MRF at a School Reach Out (3).jpg";
import Card41 from "../Images/MRF/MRF at a School Reach Out (4).jpg";
import Card48 from "../Images/MRF/MRF Executive Director with a colleague and some of the children at a School Reach Out.jpg";
import Card49 from "../Images/MRF/MRF Executive Director with colleagues and some of the children at a School Reach Out.jpg";
import Card50 from "../Images/MRF/MRF Executive Director with some of the students at a Secondary School OutReach (2).jpg";
import Card51 from "../Images/MRF/MRF Executive Director with some of the students at a Secondary School OutReach.jpg";
import Card42 from "../Images/MRF/MRF at a School Reach Out (5).jpg";
import Card43 from "../Images/MRF/MRF at a School Reach Out (6).jpg";
import Card44 from "../Images/MRF/MRF at a School Reach Out (7).jpg";
import Card45 from "../Images/MRF/MRF at a School Reach Out (8).jpg";
import Card46 from "../Images/MRF/MRF at a School Reach Out on Sexual Reproductive Health.jpg";
import Card47 from "../Images/MRF/MRF at a School Reach Out.jpg";
import Card52 from "../Images/MRF/MRF staff and beneficiaries from the MRF OutReach (2).jpg";
import Card53 from "../Images/MRF/MRF staff and beneficiaries from the MRF OutReach (3).jpg";
import Card54 from "../Images/MRF/MRF staff and beneficiaries from the MRF OutReach.jpg";
import Card55 from "../Images/MRF/MRF staff and children with their received sanitary pads at the School Reach Out.jpg";
import Card56 from "../Images/MRF/MRF staff and some of the students at the MRF Secondary School OutReach (2).jpg";
import Card57 from "../Images/MRF/MRF staff and some of the students at the MRF Secondary School OutReach (3).jpg";
import Card58 from "../Images/MRF/MRF staff and some of the students at the MRF Secondary School OutReach (4).jpg";
import Card59 from "../Images/MRF/MRF staff and some of the students at the MRF Secondary School OutReach (5).jpg";
import Card60 from "../Images/MRF/MRF staff and some of the students at the MRF Secondary School OutReach.jpg";
import Card61 from "../Images/MRF/MRF staff giving out health sanitary pads to school children at a School Reach Out (2).jpg";
import Card62 from "../Images/MRF/MRF staff giving out health sanitary pads to school children at a School Reach Out.jpg";
import Card63 from "../Images/MRF/MRF staff giving out soap to a beneficiary (2).jpg";
import Card64 from "../Images/MRF/MRF staff giving out soap to a beneficiary.jpg";
import Card65 from "../Images/MRF/MRF staff giving out soap to beneficiaries (2).jpg";
import Card66 from "../Images/MRF/MRF staff giving out soap to beneficiaries (3).jpg";
import Card67 from "../Images/MRF/MRF staff giving out soap to beneficiaries.jpg";
import Card68 from "../Images/MRF/MRF staff with some of beneficiaries at the OutReach (2).jpg";
import Card69 from "../Images/MRF/MRF staff with some of beneficiaries at the OutReach.jpg";
import Card70 from "../Images/MRF/MRF staff with some of the children at a School Reach Out.jpg";
import Card71 from "../Images/MRF/MRF tank for the WASH program at one of the beneficiaries.jpg";
import Card72 from "../Images/MRF/One of the children sharing with a friend about the received sanitary pad (2).jpg";
import Card73 from "../Images/MRF/One of the children sharing with a friend about the received sanitary pad (3).jpg";
import Card74 from "../Images/MRF/One of the children sharing with a friend about the received sanitary pad (4).jpg";
import Card75 from "../Images/MRF/One of the children sharing with a friend about the received sanitary pad.jpg";
import Card76 from "../Images/MRF/Refreshment time at the Secondary School Reach Out.jpg";
import Card77 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach (2).jpg";
import Card78 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads (2).jpg";
import Card79 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads (3).jpg";
import Card80 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads (4).jpg";
import Card81 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads (5).jpg";
import Card82 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads.jpg";
import Card83 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach with their sanitary pads.jpg";
import Card84 from "../Images/MRF/Some of the students at the MRF Secondary School OutReach.jpg";
import Card85 from "../Images/MRF/Student at the MRF Secondary School OutReach.jpg";
import Card86 from "../Images/MRF/Student ready to ask a question at the MRF Secondary School OutReach.jpg";
import Card87 from "../Images/MRF/Students at the MRF Secondary School OutReach.jpg";
import Card88 from "../Images/MRF/Students ready to ask a question at the MRF Secondary School OutReach.jpg";
import Card89 from "../Images/MRF/Students with their received sanitary pads at the Secondary School Reach Out (2).jpg";
import Card90 from "../Images/MRF/Students with their received sanitary pads at the Secondary School Reach Out (3).jpg";
import Card91 from "../Images/MRF/Students with their received sanitary pads at the Secondary School Reach Out (4).jpg";
import Card92 from "../Images/MRF/Students with their received sanitary pads at the Secondary School Reach Out (5).jpg";
import Card93 from "../Images/MRF/Students with their received sanitary pads at the Secondary School Reach Out.jpg";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function PhotoGallery() {
  let data = [
    {
      id: 31,
      imgSrc: Card31,
      caption: "A beneficiary from the MRF OutReach",
    },
    {
      id: 32,
      imgSrc: Card32,
      caption: "A beneficiary from the MRF OutReach",
    },
    {
      id: 33,
      imgSrc: Card33,
      caption: "A student holding her received sanitary pad",
    },
    {
      id: 34,
      imgSrc: Card34,
      caption: "Beneficiaries with some of the staff from the MRF OutReach",
    },
    {
      id: 35,
      imgSrc: Card35,
      caption: "Beneficiary children at an MRF OutReach",
    },
    {
      id: 36,
      imgSrc: Card36,
      caption: "Children with their received sanitary pads at the School Reach Out",
    },
    {
      id: 37,
      imgSrc: Card37,
      caption: "Children with their received sanitary pads at the School Reach Out",
    },
    {
      id: 38,
      imgSrc: Card38,
      caption: "Some of the children attending the MRF School Reach Out",
    },
    {
      id: 39,
      imgSrc: Card39,
      caption: "The MRF ED with some of the children attending the MRF School Reach Out",
    },
    {
      id: 40,
      imgSrc: Card40,
      caption: "Some of the children attending the MRF School Reach Out",
    },
    {
      id: 41,
      imgSrc: Card41,
      caption: "Childen attending an MRF School Reach Out",
    },
    {
      id: 42,
      imgSrc: Card42,
      caption: "The MRF ED talking to some of the children attending the MRF School Reach Out",
    },
    {
      id: 43,
      imgSrc: Card43,
      caption: "Some of the childen attending an MRF School Reach Out",
    },
    {
      id: 44,
      imgSrc: Card44,
      caption: "Some of the childen attending an MRF School Reach Out",
    },
    {
      id: 45,
      imgSrc: Card45,
      caption: "MRF staff with some of the childen attending an MRF School Reach Out",
    },
    {
      id: 46,
      imgSrc: Card46,
      caption: "MRF ED talking to children at a School Reach Out on Sexual Reproductive Health",
    },
    {
      id: 47,
      imgSrc: Card47,
      caption: "MRF ED talking to children at a School Reach Out",
    },
    {
      id: 48,
      imgSrc: Card48,
      caption: "MRF Executive Director with colleagues and some of the children at a School Reach Out",
    },
    {
      id: 49,
      imgSrc: Card49,
      caption: "MRF Executive Director with colleagues and some of the children at a School Reach Out",
    },
    {
      id: 50,
      imgSrc: Card50,
      caption: "MRF Executive Director talking with some of the students at a Secondary School OutReach",
    },{
      id: 1,
      imgSrc: Card1,
      caption: "Namanda Undetaking on an Adolescent Empowerment Program",
    },
    {
      id: 15,
      imgSrc: Card15,
      caption: "Namanda Sweing Machine",
    },
    {
      id: 17,
      imgSrc: Card17,
      caption: "Namanda and Family",
    },
    {
      id: 18,
      imgSrc: Card18,
      caption: "Namandas Family",
    },
    {
      id: 2,
      imgSrc: Card2,
      caption: "Borehole For Community Support",
    },
    {
      id: 3,
      imgSrc: Card3,
      caption: "Borehole For Community Support",
    },
    {
      id: 4,
      imgSrc: Card4,
      caption: "Elizabeth Receiving Support From MRF",
    },
    {
      id: 5,
      imgSrc: Card5,
      caption: "Food Items Being given to a Beneficially",
    },
    {
      id: 6,
      imgSrc: Card6,
      caption: "Jovan Before",
    },
    {
      id: 8,
      imgSrc: Card8,
      caption: "Jovan Receives Standing Frame",
    },
    {
      id: 7,
      imgSrc: Card7,
      caption: "Jovan Foodstuffs",
    },
    {
      id: 51,
      imgSrc: Card51,
      caption: "MRF Executive Director talking with some of the students at a Secondary School OutReach",
    },
    {
      id: 52,
      imgSrc: Card52,
      caption: "MRF staff and beneficiaries of the One Tank To 10 Households from the MRF OutReach",
    },
    {
      id: 53,
      imgSrc: Card53,
      caption: "MRF staff and beneficiaries of the One Tank To 10 Households from the MRF OutReach",
    },
    {
      id: 54,
      imgSrc: Card54,
      caption: "MRF staff and beneficiaries of the One Tank To 10 Households from the MRF OutReach",
    },
    {
      id: 55,
      imgSrc: Card55,
      caption: "MRF staff and children with their received sanitary pads at the School Reach Out",
    },
    {
      id: 56,
      imgSrc: Card56,
      caption: "MRF staff and some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 57,
      imgSrc: Card57,
      caption: "MRF staff and some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 58,
      imgSrc: Card58,
      caption: "MRF staff and some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 59,
      imgSrc: Card59,
      caption: "MRF staff and some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 60,
      imgSrc: Card60,
      caption: "MRF staff and some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 61,
      imgSrc: Card61,
      caption: "MRF staff giving out health sanitary pads to school children at a School Reach Out",
    },
    {
      id: 62,
      imgSrc: Card62,
      caption: "MRF staff giving out health sanitary pads to school children at a School Reach Out",
    },
    {
      id: 63,
      imgSrc: Card63,
      caption: "MRF staff giving out soap to a beneficiary",
    },
    {
      id: 64,
      imgSrc: Card64,
      caption: "MRF staff giving out soap to a beneficiary",
    },
    {
      id: 65,
      imgSrc: Card65,
      caption: "MRF staff giving out soap to a beneficiary",
    },
    {
      id: 9,
      imgSrc: Card9,
      caption: "Launching MRF Community Borehole",
    },
    {
      id: 10,
      imgSrc: Card10,
      caption: "Lynnet Receives Pampers",
    },
    {
      id: 11,
      caption: "Lynnet",
      imgSrc: Card11,
    },
    {
      id: 12,
      imgSrc: Card12,
      caption:
        "Masaka NGO Forum Surveying Some Tanks Given Out By MRF - Nalongo",
    },
    {
      id: 14,
      imgSrc: Card14,
      caption: "Mukadde Namagembe",
    },
    {
      id: 13,
      imgSrc: Card13,
      caption:
        "Masaka NGO Forum Surveying Some Tanks Given Out By MRF - Namagembe",
    },
    {
      id: 22,
      imgSrc: Card22,
      caption: "Shamiras Arm Before",
    },
    {
      id: 20,
      imgSrc: Card20,
      caption: "Shamira Now Receiving Formal Education",
    },
    {
      id: 21,
      imgSrc: Card21,
      caption: "Shamira Now Receiving Formal Education",
    },
    {
      id: 23,
      imgSrc: Card23,
      caption: "MRF Tank Sticker",
    },
    {
      id: 24,
      imgSrc: Card24,
      caption: "MRF Tank",
    },
    {
      id: 25,
      imgSrc: Card25,
      caption: "Wash Program - Water Tank",
    },
    {
      id: 26,
      imgSrc: Card26,
      caption: "Wash Program - Water Tank",
    },
    {
      id: 27,
      imgSrc: Card27,
      caption: "Water Tank For Mukadde Namagembe",
    },
    {
      id: 28,
      imgSrc: Card28,
      caption: "Water, Snitation and Hygiene (WASH) Program",
    },
    {
      id: 29,
      imgSrc: Card29,
      caption: "Water, Snitation and Hygiene (WASH) Program",
    },
    {
      id: 30,
      imgSrc: Card30,
      caption: "Water, Snitation and Hygiene (WASH) Program",
    },
    {
      id: 16,
      imgSrc: Card16,
      caption: "Namanda Sweing Machine",
    },
    {
      id: 66,
      imgSrc: Card66,
      caption: "MRF staff giving out soap to a beneficiary",
    },
    {
      id: 67,
      imgSrc: Card67,
      caption: "MRF staff giving out soap to a beneficiary",
    },
    {
      id: 68,
      imgSrc: Card68,
      caption: "MRF staff and beneficiaries of the One Tank To 10 Households from the MRF OutReach",
    },
    {
      id: 69,
      imgSrc: Card69,
      caption: "MRF staff with some of beneficiaries at the OutReach",
    },
    {
      id: 70,
      imgSrc: Card70,
      caption: "MRF staff with some of the children at a School Reach Out",
    },
    {
      id: 71,
      imgSrc: Card71,
      caption: "MRF One Tank To 10 Households for the WASH program at one of the beneficiaries home",
    },
    {
      id: 72,
      imgSrc: Card72,
      caption: "One of the children helping out serve a friend a sanitary pad",
    },
    {
      id: 73,
      imgSrc: Card73,
      caption: "One of the children sharing with a friend about the received sanitary pad",
    },
    {
      id: 74,
      imgSrc: Card74,
      caption: "One of the children helping out serve a friend a sanitary pad",
    },
    {
      id: 75,
      imgSrc: Card75,
      caption: "One of the children helping out serve a friend a sanitary pad",
    },
    {
      id: 76,
      imgSrc: Card76,
      caption: "Refreshment time at the Secondary School Reach Out",
    },
    {
      id: 77,
      imgSrc: Card77,
      caption: "Some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 78,
      imgSrc: Card78,
      caption: "Some of the students at the MRF Secondary School OutReach getting ready to receive sanitary pads",
    },
    {
      id: 79,
      imgSrc: Card79,
      caption: "Some of the students at the MRF Secondary School OutReach receiving sanitary pads",
    },
    {
      id: 80,
      imgSrc: Card80,
      caption: "Some of the students at the MRF Secondary School OutReach receiving sanitary pads",
    },
    {
      id: 81,
      imgSrc: Card81,
      caption: "Some of the students at the MRF Secondary School OutReach receiving sanitary pads",
    },
    {
      id: 82,
      imgSrc: Card82,
      caption: "Some of the students at the MRF Secondary School OutReach receiving sanitary pads",
    },
    {
      id: 83,
      imgSrc: Card83,
      caption: "Some of the students at the MRF Secondary School OutReach with their received sanitary pads",
    },
    {
      id: 84,
      imgSrc: Card84,
      caption: "Some of the students at the MRF Secondary School OutReach",
    },
    {
      id: 85,
      imgSrc: Card85,
      caption: "Student at the MRF Secondary School OutReach",
    },
    {
      id: 86,
      imgSrc: Card86,
      caption: "A student ready to ask a question at the MRF Secondary School OutReach",
    },
    {
      id: 87,
      imgSrc: Card87,
      caption: "Students listening in at the MRF Secondary School OutReach",
    },
    {
      id: 88,
      imgSrc: Card88,
      caption: "Students ready to ask questions at the MRF Secondary School OutReach",
    },
    {
      id: 89,
      imgSrc: Card89,
      caption: "Students with their received sanitary pads at the Secondary School Reach Out",
    },
    {
      id: 90,
      imgSrc: Card90,
      caption: "Students with their received sanitary pads at the Secondary School Reach Out",
    },
    {
      id: 91,
      imgSrc: Card91,
      caption: "Students with their received sanitary pads at the Secondary School Reach Out",
    },
    {
      id: 92,
      imgSrc: Card92,
      caption: "Students with their received sanitary pads at the Secondary School Reach Out",
    },
    {
      id: 93,
      imgSrc: Card93,
      caption: "Students with their received sanitary pads at the Secondary School Reach Out",
    },
  ];

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const getImg = (imgSrc, index) => {
    setTempImgSrc(imgSrc);
    setCurrentIndex(index);
    setModel(true);
  };

  const goToNext = () => {
    if (currentIndex + 1 < data.length) {
      setCurrentIndex(currentIndex + 1);
      setTempImgSrc(data[currentIndex + 1].imgSrc);
    }
  };

  const goToPrev = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
      setTempImgSrc(data[currentIndex - 1].imgSrc);
    }
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="" />
        <div className="caption">{data[currentIndex].caption}</div>
        <div className="closeicon">
          <CloseIcon onClick={() => setModel(false)} />
        </div>
        <div className="previcon">
          <ArrowBackIosIcon onClick={goToPrev} />
        </div>
        <div className="nexticon">
          <ArrowForwardIosIcon onClick={goToNext} />
        </div>
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc, index)}
            >
              <img src={item.imgSrc} style={{ width: "100%" }} alt="" />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default PhotoGallery;
