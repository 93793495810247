import React from "react";
import Card1 from "../Images/Headers/reproductive-health.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Reproductive Health.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function ReproductiveHealth() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Reproductive Health</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Reproductive Health
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Reproductive Health</b>
              </h>
              <p className="texthome1">
                Masaka Relief Foundation (MRF) takes a multifaceted approach to
                reproductive health, emphasizing behavioral change
                communication, awareness, and sensitization. Through guidance
                and counseling, the organization addresses the mental and
                emotional aspects of reproductive health, ensuring that
                beneficiaries have access to accurate information and support.
              </p>
              <p className="texthome1">
                Distribution of educational materials and HIV/AIDS counseling
                and testing contribute to informed decision-making and
                prevention efforts. MRF's collaboration with school peer health
                clubs and community outreach further promotes positive
                reproductive health practices, creating a comprehensive
                ecosystem of support that extends across age groups and
                communities. The domain of reproductive health largely involves:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Youth-Focused Programs:</b> To address the unique
                    reproductive health needs of adolescents, MRF establishes
                    youth-friendly reproductive health services and education
                    programs. This includes providing age-appropriate
                    information and access to contraceptives.
                  </li>
                  <p></p>
                  <li>
                    <b>Maternal Health:</b> Expanding support for maternal
                    health, including prenatal and postnatal care,
                    contributes to reducing maternal mortality rates. MRF
                    works with local health facilities to improve access to these
                    services.
                  </li>
                  <p></p>
                  <li>
                    <b>Community Health Workers:</b> Training and employing
                    community health workers who specialize in reproductive
                    health helps disseminate information, provide counseling,
                    and connect individuals with appropriate services.
                  </li>
                  <p></p>
                  <li>
                    <b>Gender Sensitization:</b> Incorporating gender
                    sensitization into reproductive health programs addresses
                    issues like gender-based violence and empower women and
                    girls to make informed decisions about their reproductive
                    health.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReproductiveHealth;
