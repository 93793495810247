import React from "react";
import Card2 from "../Images/Headers/where-we-work.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card1 from "../Images/coming.jpg";

function WhereweWork() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="rounded shape with shadow on hover" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Where We Work</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Where We Work</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <img
                src={Card1}
                alt="rounded shape with shadow on hover"
                height="500"
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhereweWork;