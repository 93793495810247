import React from 'react';
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import "../Components/NavigatBar.css";
import Logo from "../Images/Logo.jpg";
import { useState } from 'react';

function NavigatBar() {

  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showWhatWeDo, setShowWhatWeDo] = useState(false);
  const [showPublicity, setShowPublicity] = useState(false);

  const showAboutUsDropdown = (e) => {
    setShowAboutUs(!showAboutUs);
  }
  const hideAboutUsDropdown = e => {
    setShowAboutUs(false);
  }

  const showWhatWeDoDropdown = (e) => {
    setShowWhatWeDo(!showWhatWeDo);
  }
  const hideWhatWeDoDropdown = e => {
    setShowWhatWeDo(false);
  }

  const showPublicityDropdown = (e) => {
    setShowPublicity(!showPublicity);
  }
  const hidePublicityDropdown = e => {
    setShowPublicity(false);
  }

  return (
    <section>
      <div className='container'>
        <div className="row">
          <Navbar className="navba" collapseOnSelect expand="lg" fixed="top">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src={Logo}
                  alt="logo of mrf"
                  style={{
                    height: 70, width: 70, borderRadius: '50%'
                  }}
                  onMouseEnter={(event) => event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'}
                  onMouseLeave={(event) => event.target.style.boxShadow = 'none'}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="/" className="home active">Home</Nav.Link>
                  <NavDropdown title="About Us" id="collasible-nav-dropdown"
                    show={showAboutUs} onMouseEnter={showAboutUsDropdown}
                    onMouseLeave={hideAboutUsDropdown} className="dropdown home">
                    <NavDropdown.Item href="/about-us/our-team" className="home">Our Team</NavDropdown.Item>
                    <NavDropdown.Item href="/about-us/who-we-are" className="home">Who We Are</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/about-us/our-partners" className="home">Our Partners</NavDropdown.Item>
                    </NavDropdown>
                  <NavDropdown title="What We Do" id="collasible-nav-dropdown"
                    show={showWhatWeDo} onMouseEnter={showWhatWeDoDropdown}
                    onMouseLeave={hideWhatWeDoDropdown} className="dropdown home">
                    <NavDropdown.Item href="/what-we-do/advocacy" className="home">Advocacy</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/education" className="home">Education</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/child-protection" className="home">Child Protection</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/emergency-and-relief" className="home">Emergency & Relief</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/livelihoods-support" className="home">Livelihoods Support</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/reproductive-health" className="home">Reproductive Health</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/what-we-do/water-sanitation-and-hygiene" className="home">WASH (Water, Sanitation & Hygiene)</NavDropdown.Item>
                    <NavDropdown.Item href="/what-we-do/environmental-protection-and-conservation" className="home">Environmental Protection & Conservation</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/reports-publications" className="home">Reports & Publications</Nav.Link>
                </Nav>
                <Nav>
                  <NavDropdown title="Media" id="collasible-nav-dropdown"
                    show={showPublicity} onMouseEnter={showPublicityDropdown}
                    onMouseLeave={hidePublicityDropdown} className="dropdown home">
                    <NavDropdown.Item href="/media/testimonies" className="home">Testimonies</NavDropdown.Item>
                    <NavDropdown.Item href="/media/news-and-blog" className="home">News & Blog</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/media/photo-gallery" className="home">Photo Gallery</NavDropdown.Item>
                    <NavDropdown.Item href="/media/video-gallery" className="home">Video Gallery</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/contact-us" className="home">Contact Us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </section>
  )
}

export default NavigatBar;
