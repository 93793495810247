import React from "react";
import Card1 from "../Images/Headers/our-team.jpg";
import Card3 from "../Images/Profiles/Namayengo Madine - Executive Director.jpg";
import Card4 from "../Images/Profiles/Kabanda Robert - Quality Control and compliance.jpeg";
import Card5 from "../Images/Profiles/MULINDWA JUDE - Board Chairperson.jpeg";
import Card6 from "../Images/Profiles/NALUJJA BETTY - Public Relations Officer.jpeg";
import Card7 from "../Images/Profiles/Namuli Annet - Admin. Assistant.jpeg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ListGroup from "react-bootstrap/ListGroup";

function OurTeam() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="shape of person" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Our Team</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Our Team</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="py-4 border-bottom">
        <div className="container border-top">
          <div className="container-flex">
            <div className="row">
              <div className="col-md-12">
                <h4 className="main-heading pt-3">
                  Executive Director's Message
                </h4>
                <div className="underline-card4"></div>
                <p className="textBOD">
                Welcome to the inspiring world of Masaka Relief Foundation (MRF). 
                We take immense pride in our remarkable journey since our inception in 2021, 
                and our optimism for a bright future knows no bounds. Throughout our dedicated efforts in Greater Masaka, 
                we have achieved tremendous success, 
                transitioning from addressing critical housing issues to forging a profound 
                bond with our community. 
                Together, we stand united in our mission to eradicate social injustices 
                and promote the overall well-being of our cherished communities.
                </p>
                <p className="textBOD">
                The unwavering support of our local government, committed staff, dear friends, 
                and our community has been instrumental in securing the necessary resources to 
                propel us towards our goals. This collective support fuels us to continually develop 
                new strategies and form meaningful partnerships. As a result, we ensure that families 
                in our communities have access to safe and stable water supply, quality education, 
                and more.
                </p>
                <p className="textBOD">
                At MRF, we hold dear the growth and development of children, empowering youth with 
                knowledge and skills to become responsible adults, and nurturing the dreams of mothers 
                of childbearing age. Our impact on Uganda's children, youth, and economically supported families has been 
                nothing short of tremendous. MRF continues to champion social and legal justice, 
                emphasizing the right to health, education, water, sanitation, and hygiene. 
                This commitment is attested by a wide array of stakeholders, including Central and 
                Local governments, Civil Society Organizations, the community, and development partners. Perhaps our proudest legacy lies in the positive transformation we have brought to children, 
                mothers, youth, and empowered communities — fostering social justice and peace within households and among neighbors.
                </p> 
                <p className="textBOD">
                Throughout our service, we remain guided by our unwavering Vision, Mission, and core values, 
                which inspire us not only to pursue our cause but also to lead by example in stewardship and 
                accountability to our valued stakeholders. With unwavering determination, MRF will continue to fulfill its mandate and eagerly looks 
                forward to accomplishing all planned activities. As we extend assistance and relief, we 
                eagerly anticipate the opportunity to collaborate with you, making a difference far and 
                wide. Together, we'll create a brighter and more hopeful future for all.
                </p>                
                <div className="mb-2 signoff">
                  <h3 className="signoff">Ms. Namayengo Madrine </h3>
                  <p className="textsignoff">Executive Director</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row border-top text-center">
              <div className="container py-2">
                <div className="row text-center">
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <p className="bold2">
                          <Card.Img
                            variant="top"
                            src={Card3}
                            alt="shape of person"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(249, 143, 34, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </p>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <h3 className="bold2">Ms. Namayengo Madrine </h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Executive Director</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <TwitterIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/2567812362217"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:mrf19.ug@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <p className="bold2">
                          <Card.Img
                            variant="top"
                            src={Card5}
                            alt="shape of person"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(249, 143, 34, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </p>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <h3 className="bold2">Mr. Mulindwa Jude</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Board Chairperson </h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <TwitterIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/2567812362217"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:mrf19.ug@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <p className="bold2">
                          <Card.Img
                            variant="top"
                            src={Card4}
                            alt="shape of person"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(249, 143, 34, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </p>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <h3 className="bold2">Mr. Kabanda Robert</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">
                                Head Quality Control and Compliance
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <TwitterIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/2567812362217"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:mrf19.ug@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <p className="bold2">
                          <Card.Img
                            variant="top"
                            src={Card6}
                            alt="shape of person"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(249, 143, 34, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </p>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <h3 className="bold2">Ms. Nalujja Betty</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Public Relations Officer</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <TwitterIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/2567812362217"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:mrf19.ug@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <p className="bold2">
                          <Card.Img
                            variant="top"
                            src={Card7}
                            alt="shape of person"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(249, 143, 34, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </p>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <h3 className="bold2">Ms. Namuli Annet</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">
                                Administrative Assistant
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <TwitterIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/2567812362217"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:mrf19.ug@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurTeam;