import React from "react";
import Card1 from "../Images/Headers/jovan-progress.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Child Protection.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function ChildProtection() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Child Protection</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Child Protection
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Child Protection</b>
              </h>
              <p className="texthome1">
                MRF's initiatives in child protection encompass a comprehensive
                array of strategies that involve both community empowerment and
                policy advocacy. By establishing and training Child Protection
                Committees, the organization ensures that local communities are
                actively engaged in safeguarding children's rights and
                well-being.
                </p>
                <p className="texthome1">
                The facilitation of Community Dialogue Meetings
                provides a platform for discussing child protection concerns,
                fostering understanding, and building consensus on how to
                address them effectively. MRF's efforts extend beyond awareness
                to tangible support for community-led initiatives, translating
                key legal and policy documents into local languages to enhance
                accessibility, and collecting data on violations of children's
                rights to inform evidence-based interventions. Moreover, the
                support for formal education among OVCs ensures that they
                receive a protective and nurturing environment that contributes
                to their holistic development.
              </p>
              <p className="texthome1">
                Child protection activities conducted by MRF encompass a wide
                range of initiatives to ensure the safety and well-being of
                children. Within this domain, the scope includes:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Capacity Building:</b> Continuously training and
                    capacitating Child Protection Committees ensures their
                    effectiveness in addressing child protection issues. Ongoing
                    training also includes psychosocial support for affected
                    children.
                  </li>
                  <p></p>
                  <li>
                    <b>Community Sensitization:</b> Broadening the scope of
                    community dialogue meetings to address various child
                    protection issues, including child labor, child marriage,
                    and child trafficking, can create a more comprehensive
                    approach to safeguarding children.
                  </li>
                  <p></p>
                  <li>
                    <b>Data-Driven Interventions:</b> Continuously gathering
                    data on violations of children's rights to inform targeted
                    interventions and reporting incidents to appropriate
                    authorities. This data can inform targeted interventions and
                    policy advocacy.
                  </li>
                  <p></p>
                  <li>
                    <b>Legal Literacy:</b> In addition to translating legal and
                    policy documents into local languages, MRF provides legal
                    literacy workshops to community members. This empowers them
                    to understand and protect children's rights effectively.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ChildProtection;
