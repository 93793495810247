import React from "react";
import Card1 from "../Images/Headers/namanda.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Education.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Education() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Education</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Education</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Education</b>
              </h>
              <p className="texthome1">
                Education is a foundational pillar within every program of
                Masaka Relief Foundation (MRF). The organization's commitment to
                supporting Orphans and Vulnerable Children (OVCs) reflects a
                holistic approach to addressing the challenges faced by
                marginalized youth. By providing scholastic materials, school
                fees, and basic essentials, MRF not only increases access to
                formal education but also contributes to breaking the cycle of
                poverty.
              </p>
              <p className="texthome1">
                The significance of education as a cross-cutting activity cannot
                be overstated, as it empowers OVCs with essential knowledge and
                skills, enabling them to make informed decisions and pursue
                brighter futures. Despite funding constraints, the
                organization's dedication to supporting 10 OVCs showcases its
                determination to make a tangible impact, and collaboration with
                other stakeholders remains crucial to extending this support to
                a broader spectrum of beneficiaries. To expand within the domain
                of education, MRF looks at undertaking on the following:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Capacity Building:</b> MRF engages in capacity-building
                    efforts for both children and parents. This involves
                    training parents to support their children's education
                    effectively and enhancing the educational skills and
                    knowledge of OVCs.
                  </li>
                  <p></p>
                  <li>
                    <b>Resource Mobilization:</b> MRF explores additional
                    avenues for funding, such as seeking partnerships with local
                    businesses, applying for grants, or organizing fundraising
                    events. This diversification of funding sources helps
                    support more orphaned and vulnerable children.
                  </li>
                  <p></p>
                  <li>
                    <b>Scholarship Programs:</b> In addition to providing basic
                    educational materials and school fees, MRF in the near
                    future looks at establishing scholarship programs that cover
                    a broader range of educational expenses, including tutoring,
                    uniforms, and extracurricular activities.
                  </li>
                  <p></p>
                  <li>
                    <b>Community Literacy:</b> Beyond supporting individual
                    children, MRF also promote community literacy programs to
                    improve overall literacy rates within the communities it
                    serves. This involves adult education classes or literacy
                    initiatives for out-of-school children.
                  </li>
                  <p></p>
                  <li>
                    <b>Advocacy for Education:</b> MRF also advocates for the
                    right to education within the communities it works in. This
                    includes raising awareness about the importance of
                    education, addressing barriers to access, and ensuring that
                    education is inclusive and equitable.
                  </li>
                  <p></p>
                  <li>
                    <b>Monitoring and Evaluation:</b> Monitoring the progress of
                    supported OVCs and evaluating the impact of educational
                    interventions is a crucial aspect. MRF continuously assesses
                    the effectiveness of its education-related programs and make
                    necessary adjustments.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Education;
