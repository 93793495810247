import React from "react";
import Card1 from "../Images/Headers/Emergency-&-Relief.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Emergency and Relief.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function EmergencyRelief() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Emergency and Relief</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Emergency and Relief
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Emergency and Relief</b>
              </h>
              <p className="texthome1">
                In times of crisis, MRF's emergency and relief efforts provide
                essential aid to disadvantaged community groups. This goes
                beyond immediate food assistance to encompass training in food
                security and agricultural rehabilitation. By imparting knowledge
                and skills related to sustainable food production and resource
                management, MRF empowers communities to become more resilient in
                the face of adversity. This approach aligns with the
                organization's commitment to long-term solutions, aiming not
                only to alleviate immediate suffering but also to equip
                communities with the tools to rebuild and thrive independently.
              </p>
              <p className="texthome1">
                Within this domain, the scope includes:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Community Preparedness:</b> MRF engages in
                    community-based disaster risk reduction programs, including
                    training communities to be better prepared for emergencies.
                    This proactive approach minimizes the impact of
                    disasters.
                  </li>
                  <p></p>
                  <li>
                    <b>Livelihoods Diversification:</b> Expanding livelihoods
                    support programs helps communities become more resilient
                    in the face of emergencies. This might involve training in
                    alternative income-generating activities that can be
                    sustained during crises.
                  </li>
                  <p></p>
                  <li>
                    <b>Coordination: </b> Strengthening coordination with local
                    and national disaster response agencies ensures a more
                    effective and efficient response to emergencies.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EmergencyRelief;
