import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import NavigatBar from "./Components/NavigatBar";
import PhotoGallery from "./Pages/PhotoGallery";
import VideoGallery from "./Pages/VideoGallery";
import Testimonies from "./Pages/Testimonies";
import NewsandBlog from "./Pages/NewsandBlog";
import WhereweWork from "./Pages/WhereweWork";
import OurPartners from "./Pages/OurPartners";
import ReportsPublications from "./Pages/ReportsPublications";
import OurTeam from "./Pages/OurTeam";
import WhoWeAre from "./Pages/WhoWeAre";
import Footer from './Components/Footer';
import ChildProtection from './Pages/ChildProtection';
import LivelihoodsSupport from './Pages/LivelihoodsSupport';
import WASHi from './Pages/WASHi';
import Education from './Pages/Education';
import EmergencyRelief from './Pages/EmergencyRelief';
import EnvironmentalProtectionConservation from './Pages/EnvironmentalProtectionConservation';
import ReproductiveHealth from './Pages/ReproductiveHealth';
import Advocacy from './Pages/Advocacy';


function App() {
  return (
    <Router>
      <div>
        <NavigatBar />
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/contact-us" component={Contact}/>
            <Route path="/media/photo-gallery" component={PhotoGallery}/>
            <Route path="/media/video-gallery" component={VideoGallery}/>
            <Route path="/media/news-and-blog" component={NewsandBlog}/>
            <Route path="/media/testimonies" component={Testimonies}/>
            <Route path="/where-we-work" component={WhereweWork}/>
            <Route path="/reports-publications" component={ReportsPublications} />
            <Route path="/about-us/our-team" component={OurTeam}/>
            <Route path="/about-us/who-we-are" component={WhoWeAre} />
            <Route path="/about-us/our-partners" component={OurPartners} />
            <Route path="/what-we-do/child-protection" component={ChildProtection} />
            <Route path="/what-we-do/livelihoods-support" component={LivelihoodsSupport} />
            <Route path="/what-we-do/education" component={Education}/>
            <Route path="/what-we-do/emergency-and-relief" component={EmergencyRelief}/>
            <Route path="/what-we-do/environmental-protection-and-conservation" component={EnvironmentalProtectionConservation}/>
            <Route path="/what-we-do/reproductive-health" component={ReproductiveHealth}/>
            <Route path="/what-we-do/water-sanitation-and-hygiene" component={WASHi}/>
            <Route path="/what-we-do/advocacy" component={Advocacy}/>

        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;