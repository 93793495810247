import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Card1 from "../Images/Carousel/IMG_7687.jpg";
import Card2 from "../Images/Carousel/IMG_7952.jpg";
import Card3 from "../Images/Carousel/IMG_7995.jpg";
import Card4 from "../Images/Carousel/IMG_7757.jpg";
import Card5 from "../Images/Carousel/IMG_7763.jpg";
import Card6 from "../Images/Carousel/IMG_7817.jpg";
import Card7 from "../Images/Carousel/IMG_7843.jpg";
import Card8 from "../Images/Carousel/IMG_7861.jpg";
import Card9 from "../Images/Carousel/IMG_7900.jpg";
import Card10 from "../Images/Carousel/IMG_7939.jpg";
import Card11 from "../Images/Carousel/outreach.jpg";
import Card12 from "../Images/Carousel/outreach1.jpg";
import Card13 from "../Images/Carousel/outreach2.jpg";
import Card14 from "../Images/Carousel/outreach3.jpg";
import Card15 from "../Images/Carousel/outreach4.jpg";
import Card16 from "../Images/Carousel/outreach5.jpg";
import Card17 from "../Images/Carousel/outreach6.jpg";
import "../Components/Carousel.css";

function Carousels() {
  return (
    <div className="container-flex carous">
      <Carousel fade interval={8000}>
        <Carousel.Item>
          <img className="imageC" src={Card1} alt="First slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>Primary School OutReach Program</b>
            </h5>
            <p className="carouseltext1">
              Some of the Children during an interaction at a Primary School
              OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card11} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Community OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Staff with a beneficiary Family of the Community OutReach Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card2} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>One Tank To 10 Households Program</b>
            </h5>
            <p className="carouseltext1">
              Staff with a beneficiary Family of the One Tank To 10 Households
              Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card12} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Community OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Staff During a Community OutReach Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card3} alt="Third slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>WASH (Water, Sanitation, and Hygiene) Program</b>
            </h5>
            <p className="carouseltext1">
              Staff with a beneficiary Family of the WASH (Water, Sanitation,
              and Hygiene) Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card13} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>One Tank To 10 Households Program</b>
            </h5>
            <p className="carouseltext">
              Staff with a beneficiary Family of the One Tank To 10 Households
              Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card4} alt="Fourth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>Primary School OutReach Program</b>
            </h5>
            <p className="carouseltext1">
              Some of the Children who were interacted with at a Primary School
              OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card14} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>One Tank To 10 Households Program</b>
            </h5>
            <p className="carouseltext">
              Staff with a beneficiary Family of the One Tank To 10 Households
              Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card5} alt="Fifth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Primary School OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Staff with some of the Children who were interacted with at a
              Primary School OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card15} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>One Tank To 10 Households Program</b>
            </h5>
            <p className="carouseltext1">
              Staff with a beneficiary Family of the One Tank To 10 Households
              Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card6} alt="Sixth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Secondary School OutReach Program</b>
            </h5>
            <p className="carouseltext">
              The Executive Director interacting with students on Sexual
              Reproductive Health at a Secondary School OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card16} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>One Tank To 10 Households Program</b>
            </h5>
            <p className="carouseltext1">
              Staff with a beneficiary Family of the One Tank To 10 Households
              Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card7} alt="Seventh slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Secondary School OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Some of the students in attendance to a Sexual Reproductive Health
              discussion at a Secondary School OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card17} alt="Second slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Community OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Staff During a Community OutReach Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card8} alt="Eighth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>Secondary School OutReach Program</b>
            </h5>
            <p className="carouseltext">
              Some of the students who were interacted with at a Secondary
              School OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card9} alt="Nineth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext1">
              <b>Secondary School OutReach Program</b>
            </h5>
            <p className="carouseltext1">
              Students with their received sanitary pads at the Secondary School
              OutReach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="imageC" src={Card10} alt="Tenth slide" />
          <Carousel.Caption>
            <h5 className="carouseltext">
              <b>WASH (Water, Sanitation, and Hygiene) Program</b>
            </h5>
            <p className="carouseltext">
              Staff with a beneficiary Family of the WASH (Water, Sanitation,
              and Hygiene) Program.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Carousels;
