import React from "react";
import Card1 from "../Images/Headers/environmental-protection.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Environmental Protection.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function EnvironmentalProtectionConservation() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Environmental Protection and Conservation</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Environmental Protection and Conservation
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Environmental Protection and Conservation</b>
              </h>
              <p className="texthome1">
                MRF's commitment to environmental protection and conservation is
                reflected in its multifaceted initiatives. Through the training
                of Youth Support Groups and the formation of Environmental
                Protection and Conservation Clubs in schools, the organization
                instills a sense of responsibility and stewardship among the
                younger generation. The establishment of a tree nursery serves
                both as a demonstration site for sustainable practices and a
                supplier of tree seedlings, fostering reforestation efforts. The
                planned tree demonstration garden in 2022 provided an invaluable
                learning center for various stakeholders interested in
                environmental preservation, thereby contributing to a more
                sustainable and ecologically conscious community.
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Eco-Tourism Initiatives:</b> MRF explores eco-tourism as
                    a means of both generating income for local communities and
                    raising awareness about environmental conservation. This
                    could involve guided nature tours or wildlife protection
                    efforts.
                  </li>
                  <p></p>
                  <li>
                    <b>Environmental Education:</b> Expanding the reach of
                    environmental education programs to schools and communities
                    to instill a deeper understanding of the importance of
                    conservation. These programs include practical lessons in
                    sustainable farming, waste management, and wildlife
                    preservation.
                  </li>
                  <p></p>
                  <li>
                    <b>Biodiversity Conservation:</b> MRF broadens its
                    conservation efforts to protect diverse ecosystems,
                    including wetlands, forests, and aquatic habitats. This
                    involves partnering with local conservation organizations
                    and government agencies.
                  </li>
                  <p></p>
                  <li>
                    <b>Climate Change Resilience:</b> In response to climate
                    change challenges, MRF works with communities to develop
                    climate-resilient agricultural practices and promote
                    afforestation to mitigate the effects of climate change.
                  </li>
                  <p></p>
                  <li>
                    <b>Awareness Campaigns:</b> Expanding outreach through
                    awareness campaigns, workshops, and community events
                    mobilizes greater support for environmental protection and
                    conservation efforts.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EnvironmentalProtectionConservation;
