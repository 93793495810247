import React, { useState, useRef } from "react";
import Card2 from "../Images/Headers/reports-and-publications.jpg";
import Card1 from "../ReportsPublications/AnnualReport2021.pdf";
import Card3 from "../ReportsPublications/ButaayaBoreholelaunch2024.pdf";
import Card4 from "../ReportsPublications/MRFAnnualReport2022.pdf";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function ReportsPublications() {
  const [documentData, setDocumentData] = useState({});

  const fetchDocument = async (fileUrl, documentName) => {
    const response = await fetch(fileUrl);
    const data = await response.blob();
    setDocumentData(prevData => ({ ...prevData, [documentName]: data }));
  };

  const downloadRefs = {
    Card1: useRef(null),
    Card3: useRef(null),
    Card4: useRef(null)
  };

  const handleDownload = async (fileUrl, documentName, fileName) => {
    if (!documentData[documentName]) {
      await fetchDocument(fileUrl, documentName);
    }
    
    const url = window.URL.createObjectURL(documentData[documentName]);
    downloadRefs[documentName].current.href = url;
    downloadRefs[documentName].current.download = fileName;
    downloadRefs[documentName].current.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img
            src={Card2}
            alt="rounded shape with shadow on hover"
            height="200"
            width="100%"
          />
        </div>
        <div className="text-on-image">
          <h4 className="bold">Reports and Publications</h4>
          <div className="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Reports and Publications
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <div>
                <button onClick={() => handleDownload(Card1, "Card1", "Annual_Report_2021.pdf")}>
                  MRF Annual Report 2021
                </button>
                <p></p>
                <button onClick={() => handleDownload(Card4, "Card4", "MRF_Annual_Report_2022.pdf")}>
                  MRF Annual Report 2022
                </button>
                <p></p>
                <button onClick={() => handleDownload(Card3, "Card3", "Butaaya_Borehole_launch_2024_SITE_Briefer.pdf")}>
                  Butaaya Borehole launch 2024_ SITE Briefer
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReportsPublications;