import React from "react";
import Card1 from "../Images/Headers/what-we-do.jpg";
import Card2 from "../Images/Logo.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WhoWeAre() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%"/>
        </div>
        <div class="text-on-image">
          <h4 className="bold">Who We Are</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Who We Are</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <p className="texthome1">
                Masaka Relief Foundation (MRF) was founded in May 2021 and is
                headquartered in Kisuuna Village, Nyendo Mukungwe Division,
                Masaka District, Uganda.
              </p>
              <p className="texthome1">
                MRF is a Local NGO that was founded in 2021 having realized that
                in Uganda, more than a third of all inhabitants live below the
                poverty line, and that Children, Adolescents and Youths are the
                Primary Victims of this Deplorable Economic Situation. Their
                rights are neglected and abused in many ways such as lack of
                access to Education, Child Marriages, Child Labor, Street
                Living, HIV/AIDS and lack of better Health Services.
              </p>
              <p>
                <b>MRF Pillars of Community Empowerment</b>
              </p>
              <p className="texthome1">
                <ul>
                  <li>
                    MRF cherishes the proper growth and development of children
                    and empowers youth with knowledge and skills to become
                    responsible adults and to realize their life goals and
                    passionate about mothers of child bearing age.
                  </li>
                  <p></p>
                  <li>
                    MRF believes that children’s rights, social and economic
                    empowerment for adolescents and youth, community inclusion
                    in development and empowerment programs should be key as a
                    mean to overall national development, and that such programs
                    must be matched by quality and equity.
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="325"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhoWeAre;
