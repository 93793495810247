import React from 'react'
import Card2 from "../Images/Headers/oUR-partners.jpg";
import Card1 from "../Images/Partners/kljb.jpg";
import Card3 from "../Images/Partners/kolping.jpg";
import Card4 from "../Images/Partners/masakalg.psd.jpg";
import Card5 from "../Images/Partners/masakango.jpg";
import Card6 from "../Images/Partners/belu.jpg";
import "../Pages/OurHistory.css"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../Pages/OurPartners.css"

function OurPartners() {
    return (
        <div>
            <div className="head-text">
                <div className="head-image">
                    <img src={Card2} alt="shape of company logo" height="200" width="100%" />
                </div>
                <div class='text-on-image'>
                    <h4 className="bold">Our Partners</h4>
                    <div class="text-center">
                        <Breadcrumb>
                            <Breadcrumb.Item className="text1" href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item className="text1">About Us</Breadcrumb.Item>
                            <Breadcrumb.Item className="text1">Our Partners</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <section className="section border-bottom">
                <div className="container mt-2 border-top">
                    <h style={{ color: '#F98F22', fontSize: '18px' }}><b>Our Partners</b></h>
                    <div className="row text-center">
                        <div className="col-md-3 py-2 imagecarded">
                            <a href="https://www.kljb.org/" target="_blank" rel="noopener noreferrer" className="bold2"><img
                                src={Card1}
                                alt="shape of company logo"
                                height="auto" width="100%" maxWidth="100%" 
                                style={{
                                    borderRadius: '5%',
                                    boxShadow: 'none',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    transform: 'scale(1) 0.5s ease-in-out',
                                }}
                                onMouseEnter={(event) => {
                                    event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'
                                    event.target.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(event) => {
                                    event.target.style.boxShadow = 'none'
                                    event.target.style.transform = 'scale(1)';
                                }}
                            /></a>
                        </div>
                        <div className="col-md-3 py-2 imagecarded">
                            <a href="https://vor-ort.kolping.de/kolpingsfamilie-lathen/" target="_blank" rel="noopener noreferrer" className="bold2"><img
                                src={Card3}
                                alt="shape of company logo"
                                height="auto" width="100%" maxWidth="100%" 
                                style={{
                                    borderRadius: '5%',
                                    boxShadow: 'none',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    transform: 'scale(1) 0.5s ease-in-out',
                                }}
                                onMouseEnter={(event) => {
                                    event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'
                                    event.target.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(event) => {
                                    event.target.style.boxShadow = 'none'
                                    event.target.style.transform = 'scale(1)';
                                }}
                            /></a>
                        </div>
                        <div className="col-md-3 py-2 imagecarded">
                            <a href="https://masaka.go.ug/" target="_blank" rel="noopener noreferrer" className="bold2"><img
                                src={Card4}
                                alt="shape of company logo"
                                height="auto" width="100%" maxWidth="100%" 
                                style={{
                                    borderRadius: '5%',
                                    boxShadow: 'none',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    transform: 'scale(1) 0.5s ease-in-out',
                                }}
                                onMouseEnter={(event) => {
                                    event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'
                                    event.target.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(event) => {
                                    event.target.style.boxShadow = 'none'
                                    event.target.style.transform = 'scale(1)';
                                }}
                            /></a>
                        </div>
                        <div className="col-md-3 py-2 imagecarded">
                            <a href="https://www.masakacityngoforum.org/" target="_blank" rel="noopener noreferrer" className="bold2"><img
                                src={Card5}
                                alt="shape of company logo"
                                height="auto" width="100%" maxWidth="100%" 
                                style={{
                                    borderRadius: '5%',
                                    boxShadow: 'none',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    transform: 'scale(1) 0.5s ease-in-out',
                                }}
                                onMouseEnter={(event) => {
                                    event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'
                                    event.target.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(event) => {
                                    event.target.style.boxShadow = 'none'
                                    event.target.style.transform = 'scale(1)';
                                }}
                            /></a>
                        </div>
                        <div className="col-md-3 py-2 imagecarded">
                            <a href="https://www.belu-uganda.de/" target="_blank" rel="noopener noreferrer" className="bold2"><img
                                src={Card6}
                                alt="shape of company logo"
                                height="auto" width="100%" maxWidth="100%" 
                                style={{
                                    borderRadius: '5%',
                                    boxShadow: 'none',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    transform: 'scale(1) 0.5s ease-in-out',
                                }}
                                onMouseEnter={(event) => {
                                    event.target.style.boxShadow = '2px 2px 2px 2px rgba(249, 143, 34, 0.4)'
                                    event.target.style.transform = 'scale(1.02)';
                                }}
                                onMouseLeave={(event) => {
                                    event.target.style.boxShadow = 'none'
                                    event.target.style.transform = 'scale(1)';
                                }}
                            /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurPartners