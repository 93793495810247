import React from "react";
import Card1 from "../Images/Headers/wash-program.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-WASH Project.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function WASHi() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%"/>
        </div>
        <div class="text-on-image">
          <h4 className="bold">WASH (Water, Sanitation, and Hygiene)</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                WASH (Water, Sanitation, and Hygiene)
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>WASH (Water, Sanitation, and Hygiene)</b>
              </h>
              <p className="texthome1">
                MRF's WASH initiatives encompass a range of interventions aimed
                at improving water access, sanitation, and hygiene practices
                within communities. By promoting community-based handwashing and
                providing water tanks, MRF empowers communities to maintain
                proper hygiene standards.
              </p>
              <p className="texthome1">
                The "One Tank To 10 Households" approach not only ensures
                sufficient water for daily activities but also supports
                agricultural needs. Collaborating with schools and healthcare
                facilities, MRF enhances access to essential WASH facilities,
                thereby contributing to infection prevention and improved
                overall health. The organization's focus on menstrual health and
                hygiene highlights its commitment to addressing gender-specific
                needs, further promoting the dignity and well-being of girls and
                women.
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Community-Led Initiatives:</b> Encouraging communities to
                    take a more active role in managing their water and
                    sanitation facilities promotes sustainability. MRF
                    provides training and support for community-led initiatives.
                  </li>
                  <p></p>
                  <li>
                    <b>Hygiene Promotion:</b> Expanding hygiene education to
                    cover a wider range of topics, including menstrual hygiene,
                    food safety, and disease prevention, have a broader
                    impact on community health.
                  </li>
                  <p></p>
                  <li>
                    <b>Technological Solutions:</b> Exploring innovative
                    technologies, such as water purification systems and
                    eco-friendly sanitation solutions, improves the
                    efficiency and sustainability of water and sanitation
                    projects.
                  </li>
                  <p></p>
                  <li>
                    <b>Research and Data Analysis:</b> Conducting research on
                    local water sources, sanitation practices, and hygiene
                    behaviors informs evidence-based interventions and track
                    progress over time.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WASHi;
