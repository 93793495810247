import React from "react";
import Card1 from "../Images/Headers/advocacy.jpg";
import Card2 from "../Images/Banners/MASAKA RELIEF BANNERS-Advocacy.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Advocacy() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card1} alt="" height="200" width="100%"/>
        </div>
        <div class="text-on-image">
          <h4 className="bold">Advocacy</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">What We Do</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">Advocacy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-8 mt-2">
              <h style={{ color: "black", fontSize: "16px" }}>
                <b>Advocacy</b>
              </h>
              <p className="texthome1">
                Advocacy within Masaka Relief Foundation (MRF) involves a
                strategic approach to addressing cross-cutting issues that
                emerge from the core programs. These issues could span across
                education, child protection, livelihoods, health, and more. To
                effectively advocate for positive change, MRF engages with
                various stakeholder groups, such as Parents Support Groups and
                Youth Support Groups, which serve as platforms for generating
                key messages. These messages aim to raise awareness, influence
                policy changes, and mobilize community support for the
                organization's programs. Through ongoing dialogues and
                interactions with these groups, MRF ensures that the concerns
                and needs of the beneficiaries are accurately represented,
                creating a more comprehensive and responsive approach to
                community development. The scope of advocacy within MRF's domain
                includes:
                <p></p>
                <ol className="mt-2">
                  <li>
                    <b>Policy Influence:</b> MRF actively engages with
                    policymakers, government agencies, and other stakeholders to
                    influence policies that impact the communities it serves.
                    This might involve advocating for changes in regulations
                    related to child protection, education, or environmental
                    conservation. By fostering partnerships, MRF ensures that
                    its advocacy messages reach a wider audience and have a more
                    significant impact.
                  </li>
                  <p></p>
                  <li>
                    <b>Community Mobilization:</b> Advocacy extends to
                    mobilizing community members to voice their concerns and
                    participate in decision-making processes. MRF facilitates
                    community dialogues and discussions to ensure that local
                    voices are heard and respected.
                  </li>
                  <p></p>
                  <li>
                    <b>Awareness Campaigns:</b> MRF conducts awareness campaigns
                    to educate the community on their rights, available
                    resources, and the importance of the various programs it
                    offers. This includes disseminating information about child
                    protection, education, and environmental conservation.
                  </li>
                  <p></p>
                  <li>
                    <b>Resource Mobilization:</b> Advocacy efforts also
                    involve seeking financial and material support from donors,
                    businesses, and other organizations to fund MRF's programs.
                    This is crucial for sustaining the organization's
                    activities.
                  </li>
                  <p></p>
                  <li>
                    <b>Capacity Building:</b> Advocacy efforts also include
                    capacity building for community members, enabling them to
                    advocate for their own rights and needs effectively. This
                    empowerment strengthens the voices of the marginalized.
                  </li>
                </ol>
              </p>
            </div>
            <div className="col-md-4 mt-2">
              <div className="imagecard">
                <img
                  src={Card2}
                  alt=""
                  height="400"
                  width="100%"
                  style={{
                    borderRadius: "5%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Advocacy;
