import React from "react";
import Card1 from "../Images/MVM/Mission.png";
import Card2 from "../Images/MVM/Vision.png";
import Card3 from "../Images/MVM/mandate.png";
import "../Components/Shadowcard.css";

const ShadowCard = ({ image, text }) => {
  const [shadow1, setShadow1] = React.useState(false);
  const [shadow2, setShadow2] = React.useState(false);
  const [shadow3, setShadow3] = React.useState(false);
  const [shadow4, setShadow4] = React.useState(false);

  return (
    <div className="container py-4 border-top">
      <div className="row">
        <div className="col-md-4 borders">
          <div
            onMouseEnter={() => setShadow1(true)}
            onMouseLeave={() => setShadow1(false)}
            style={{
              boxShadow: shadow1
                ? "0px 5px 10px 5px rgba(249, 143, 34, 0.4)"
                : "none",
              transition: "box-shadow 0.3s ease-in-out",
            }}
          >
            <div className="row">
              <div className="col-md-3 cardcont">
                <img src={Card1} alt="Card" height={50} />
              </div>
              <div className="col-md-6 cardcontext">
                <h className="mainheading" style={{ color: "#F98F22" }}>
                  Our Goal
                </h>
              </div>
            </div>
            <p className="shadowpara">
              To assist Government and Private Actors in bridging the Social and
              Economic Empowerment Gap Prevailing among Vulnerable Children,
              Adolescents and Youth.
            </p>
          </div>
        </div>
        <div className="col-md-4 borders1">
          <div
            onMouseEnter={() => setShadow2(true)}
            onMouseLeave={() => setShadow2(false)}
            style={{
              boxShadow: shadow2
                ? "0px 5px 10px 5px rgba(0, 0, 0, 0.2)"
                : "none",
              transition: "box-shadow 0.3s ease-in-out",
            }}
          >
            <div className="row">
              <div className="col-md-3 cardcont">
                <img src={Card2} alt="Card" height={50} />
              </div>
              <div className="col-md-6 cardcontext">
                <h className="mainheading" style={{ color: "#F98F22" }}>
                  Our Vision
                </h>
              </div>
            </div>
            <p className="shadowpara">
              We envision a World where Children, Youths, and Communities are
              Empowered to Live Sustainable Lives.
            </p>
          </div>
        </div>
        <div className="col-md-4 borders">
          <div
            onMouseEnter={() => setShadow3(true)}
            onMouseLeave={() => setShadow3(false)}
            style={{
              boxShadow: shadow3
                ? "0px 5px 10px 5px rgba(249, 143, 34, 0.4)"
                : "none",
              transition: "box-shadow 0.3s ease-in-out",
            }}
          >
            <div className="row">
              <div className="col-md-3 cardcont">
                <img src={Card3} alt="Card" height={50} />
              </div>
              <div className="col-md-6 cardcontext">
                <h className="mainheading" style={{ color: "#F98F22" }}>
                  Our Core Values
                </h>
              </div>
            </div>
            <p className="shadowpara">
              <h style={{ color: "black" }}>Social Justice</h>,{" "}
              <h style={{ color: "red" }}>Equity</h>,{" "}
              <h style={{ color: "purple" }}>Equality</h>,{" "}
              <h style={{ color: "blue" }}>Integrity</h>, and{" "}
              <h style={{ color: "teal" }}>Professionalism</h>.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 borders1">
          <div
            onMouseEnter={() => setShadow4(true)}
            onMouseLeave={() => setShadow4(false)}
            style={{
              boxShadow: shadow4
                ? "0px 5px 10px 5px rgba(0, 0, 0, 0.2)"
                : "none",
              transition: "box-shadow 0.3s ease-in-out",
            }}
          >
            <div className="row">
            <div className="col-md-1 cardcont">
                <img src={Card1} alt="Card" height={50} />
              </div>
              <div className="col-md-2 cardcontext">
                <h className="mainheading" style={{ color: "#F98F22" }}>
                  Our Mission
                </h>
              </div>
            </div>
            <p className="shadowpara">
              Working Together to Connect People in the Fight against Poverty,
              Investing in the Lives of Children, Adolescents and Youths, Build
              the Healthy Environments they need to Thrive, and Empower them to
              create Lasting Change in their Own Lives and Communities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadowCard;
